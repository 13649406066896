<template>
    <div>
        <div class="text-container">
            <p class="text">{{ $t('faq.playberry.step3.selectProfile') }}</p>
            <p class="text">{{ $t('faq.playberry.step3.whatsNewPage') }}</p>
        </div>

        <div class="screenshot">
            <img src="../assets/screen-3.png" :alt="$t('faq.playberry.step3.selectProfile')" :width="440" class='image'>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.text-container {
    margin-bottom: 32px;
}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF99;
}

.screenshot {
    display: flex;
    justify-content: center;
    padding: 32px 10px 0;
    border-radius: 8px;
    background-color: #141417;
}

@media only screen and (max-width: 500px) {
    .image{
        width: 100%;
    }
}
</style>
